.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}
.my-masonry-grid_column {
  background-clip: padding-box;
  justify-content: center;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 0px;
}
